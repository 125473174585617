import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import GlossaryComponent from 'components/GlossaryComponent';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageDescription mdxType="PageDescription">
      <p>{`Consistency across products plays a big role in the user experience.
This glossary provides a lightweight reference for common UI terms and action labels.
See also the `}<a parentName="p" {...{
          "href": "/guidelines/content/guidance#capitalization"
        }}>{`capitalization`}</a>{` guidance.`}</p>
    </PageDescription>
    <GlossaryComponent mdxType="GlossaryComponent" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      